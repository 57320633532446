import React, { useRef, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import ProfilePic from "../assets/ProfilePic.png";
import Services from "./Services";
import MenuItems from "./MenuItems";
import menu from "../assets/menu.svg";
import close from "../assets/cancel.svg";
import Projects from "./Projects";
import SkillSection from "./SkillSection";

const Content = () => {
  const [popClasses, setPopClasses] = useState(["pop-up", "text-center"]);
  const [blurClasses, setBlurClasses] = useState(["blurNow"]);
  const [servClass, setServC] = useState(["servComp"]);
  const [menuClass, setMenuClass] = useState(["menuItems"]);
  const [image, setImage] = useState([menu]);

  const ref = useRef(null);
  const ref2 = useRef(null);

  function chat() {
    setPopClasses(["pop-up", "text-center", "pop-ups"]);
    setBlurClasses(["blurNow", "blurback"]);
    document.body.style.overflow = "hidden";

    setMenuClass(["menuItems"]);
    setImage([menu]);
  }

  const closePop = () => {
    setPopClasses(["pop-up", "text-center"]);
    setBlurClasses(["blurNow"]);
    document.body.style.overflow = "visible";
  };

  const projectClick = () => {
    ref.current.scrollIntoView({ behavior: "smooth" });

    setMenuClass(["menuItems"]);
    setImage([menu]);
    document.body.style.overflow = "visible";
  };

  const skillClick = () => {
    ref2.current.scrollIntoView({ behavior: "smooth" });

    setMenuClass(["menuItems"]);
    setImage([menu]);
    document.body.style.overflow = "visible";
  };

  const servClick = () => {
    setServC(["servComp", "servComps"]);
    setBlurClasses(["blurNow", "blurback"]);
    document.body.style.overflow = "hidden";

    setMenuClass(["menuItems"]);
    setImage([menu]);
  };

  const servClose = () => {
    setServC(["servComp"]);
    setBlurClasses(["blurNow"]);
    document.body.style.overflow = "visible";
  };

  const menuClick = () => {
    if (
      image.find((done) => {
        return done === menu;
      })
    ) {
      setMenuClass(["menuItems", "menuItemss"]);
      setImage([close]);
      document.body.style.overflow = "hidden";
    } else if (
      image.find((done) => {
        return done === close;
      })
    ) {
      setMenuClass(["menuItems"]);
      setImage([menu]);
      document.body.style.overflow = "visible";
    }
  };

  return (
    <>
      <div className={blurClasses.join(" ")}>
        <Header
          image={image}
          chat={chat}
          projectClick={projectClick}
          skillClick={skillClick}
          servClick={servClick}
          menuClick={menuClick}
        />
        <main>
          <div className="container-fluid introduction d-flex justify-content-center pb-5">
            <div className="introFlex">
              <div>
                <div className="text1 text-white ms-4 py-4 ps-md-2 ms-lg-2">
                  <div className="d-flex">
                    <span style={{ color: "gold", fontWeight: "700" }}>
                      Hey,
                    </span>{" "}
                    <div className="mt-3 ms-2">I am</div>
                  </div>

                  <div className="parentAni">
                    <div className="animateText" data-text="Prince !"></div>
                  </div>
                </div>

                <div
                  className="mx-2 mb-2 ps-md-1 mx-lg-0"
                  style={{ fontSize: "14px", color: "white" }}
                >
                  I am a Web Developer and have expertise in Next.js, React.js,
                  Node.js, MongoDB and much more! I have been improving my
                  skills continuously, some projects showcasing a glimpse of my
                  skills are listed below, do have a look on them.
                </div>

                <button
                  className="letstalk fw-bold rounded ms-2 ms-md-3 my-3 py-sm-2 px-3 ms-lg-1 py-lg-1"
                  onClick={chat}
                >
                  Get in Touch
                </button>
              </div>

              <div className="introimg d-flex justify-content-center align-items-center mx-auto">
                <img src={ProfilePic} alt="failed to load" />
              </div>
            </div>
          </div>

          <div className="introafter text-center my-5 container-fluid text-white">
            <center>
              <h5
                ref={ref}
                className="mt-4 mb-3 awh fw-bold"
                style={{ width: "75%" }}
              >
                Projects
              </h5>
            </center>
            <Projects />

            <center>
              <h5 ref={ref2} className="awh" style={{ width: "75%" }}>
                Skills
              </h5>
            </center>
            <SkillSection />

            <center>
              <h4 className="fw-bold awh" style={{ width: "75%" }}>
                About Me
              </h4>
            </center>
            <div className="row mb-5 mt-4 d-flex justify-content-center">
              <div className="col-11 col-sm-10">
                <p className="text-start">
                  My name is <b>Prince Raj</b> and I am persuing Bachelor of
                  Computer Application (B.C.A) right now in 2022-25. I have
                  learnt my web development skills through online
                  resources/courses and have been improving them constantly.
                </p>
              </div>
              <div className="col-11 col-sm-10">
                <p className="text-start">
                  I am a Full Stack Web Developer who has expertise in multiple
                  programming languages and fronend and backend
                  frameworks/libraries, and have built some websites to showcase
                  my skills which are listed in the project section and I will
                  be listing all my future projects there as well.
                </p>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
      <div className={popClasses.join(" ")}>
        {/* <img src={userLogo} alt="" className="pophead" /> */}
        <p className="fw-bold mt-3">Connect with me through</p>
        <div>
          <div
            className="contact"
            style={{ position: "absolute", top: "70px", left: "40px" }}
          >
            <a
              target="_blank"
              rel="noreferrer"
              href="mailto:workwithprince2022@gmail.com?subject= Hi Prince"
            >
              <i
                className="fa-solid fa-envelope fa-3x"
                style={{
                  cursor: "pointer",
                  filter:
                    "invert(72%) sepia(93%) saturate(603%) hue-rotate(359deg) brightness(102%) contrast(108%)",
                }}
              ></i>
            </a>
          </div>
          <div
            className="contact"
            style={{ position: "absolute", top: "70px", right: "40px" }}
          >
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/in/iamprincerajv/"
            >
              <i
                className="fa-brands fa-linkedin fa-3x"
                style={{
                  cursor: "pointer",
                  filter:
                    "invert(72%) sepia(93%) saturate(603%) hue-rotate(359deg) brightness(102%) contrast(108%)",
                }}
              ></i>
            </a>
          </div>
          <div
            className="contact"
            style={{ position: "absolute", bottom: "50px", left: "40px" }}
          >
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/iamprincerajv/"
            >
              <i
                className="fa-brands fa-instagram fa-3x"
                style={{
                  cursor: "pointer",
                  filter:
                    "invert(72%) sepia(93%) saturate(603%) hue-rotate(359deg) brightness(102%) contrast(108%)",
                }}
              ></i>
            </a>
          </div>
          <div
            className="contact"
            style={{ position: "absolute", bottom: "50px", right: "40px" }}
          >
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.twitter.com/iamprincerajv/"
            >
              <i
                className="fa-brands fa-square-x-twitter fa-3x"
                style={{
                  cursor: "pointer",
                  filter:
                    "invert(72%) sepia(93%) saturate(603%) hue-rotate(359deg) brightness(102%) contrast(108%)",
                }}
              ></i>
            </a>
          </div>
        </div>
        <img
          onClick={closePop}
          src={close}
          alt="close"
          style={{
            filter:
              "invert(72%) sepia(93%) saturate(603%) hue-rotate(359deg) brightness(102%) contrast(108%)",
            width: "40px",
            position: "absolute",
            bottom: "-40px",
            right: "50%",
            translate: "50%",
            cursor: "pointer",
          }}
        />
      </div>
      <Services servClass={servClass} servClose={servClose} />
      <MenuItems
        menuClass={menuClass}
        chat={chat}
        projectClick={projectClick}
        skillClick={skillClick}
        servClick={servClick}
      />
    </>
  );
};

export default Content;
