import React, { useState } from 'react'
import MenuBar from './MenuBar';

const Header = (props) => {

    const [head1Color, setHead1Color] = useState('white');
    const [head2Color, setHead2Color] = useState('gold');

    setInterval(() => {
        if (head1Color === "white") {
            setHead1Color('gold');
            setHead2Color('white');
        } else {
           setHead1Color('white');
           setHead2Color('gold');
        }
    }, 1000);

    return (
        <header className="text-center text-white">
            <div className="fw-bold ps-md-5 headerp pt-3">
                <span className="head1" style={{color: head1Color}}>Port</span><span className="head2" style={{color: head2Color}}>folio</span>
            </div>

            <div className="pe-md-5 pt-3 headBMenu">
                <p className='fw-bolder headCombo p-1 pt-1 pe-3' onClick={props.skillClick}>Skills</p>
                <p className='fw-bolder headCombo p-1 pt-1 pe-3' onClick={props.servClick}>Services</p>
                <p className='fw-bolder headCombo p-1 pt-1 pe-3' onClick={props.projectClick}>Projects</p>
                <p className="headChat fw-bolder p-1 pt-1" onClick={props.chat}>Get in Touch</p>
            </div>
            <MenuBar menuClick={props.menuClick} image={props.image} />
        </header>
    )
}

export default Header
