import React, { useEffect, useState } from "react";

// assets
import nextjs from "../assets/skills/nextjs.svg";
import reactjs from "../assets/skills/reactjs.svg";
import tailwind from "../assets/skills/tailwindcss.svg";
import bootstrap from "../assets/skills/bootstrap.svg";
import html from "../assets/skills/html.svg";
import css from "../assets/skills/css.svg";
import nodejs from "../assets/skills/node.svg";
import mongodb from "../assets/skills/mongodb.svg";
import javascript from "../assets/skills/javascript.svg";
import typescript from "../assets/skills/typescript.svg";
import c from "../assets/skills/c.svg";
import cpp from "../assets/skills/cpp.svg";
import java from "../assets/skills/java.svg";
import mysql from "../assets/skills/mysql.svg";

const SkillSection = () => {

    const [currSkill, setCurrSkill] = useState([]);
    const [skillBtns, setSkillBtns] = useState([]);

  const frontend = [
    {
      name: "Next.js",
      image: nextjs,
    },
    {
      name: "React.js",
      image: reactjs,
    },
    {
      name: "Tailwind CSS",
      image: tailwind,
    },
    {
      name: "Bootstrap",
      image: bootstrap,
    },
    {
      name: "HTML",
      image: html,
    },
    {
      name: "CSS",
      image: css,
    },
  ];

  const backend = [
    {
      name: "Node.js",
      image: nodejs,
    },
  ];

  const databases = [
    {
      name: "MongoDB",
      image: mongodb,
    },
    {
      name: "MySQL",
      image: mysql,
    },
  ];

  const programmingL = [
    {
      name: "JavaScript",
      image: javascript,
    },
    {
      name: "TypeScript",
      image: typescript,
    },
    {
      name: "C++",
      image: cpp,
    },
    {
      name: "Java",
      image: java,
    },
    {
      name: "C",
      image: c,
    },
  ];

  const onLoadCheck = (btn) => {
    btn[0].style.backgroundColor = "goldenrod";
    btn[0].style.color = "black";
    btn[0].style.color = "black";
  }

  useEffect(() => {
    setCurrSkill(frontend);

    const btns = document.querySelector(".skillHeadBtns").children;

    setSkillBtns(btns);

    onLoadCheck(btns);

    //eslint-disable-next-line
  }, [])

  const handleClick = (active, id) => {
    setCurrSkill(active);

    for(const element of skillBtns) {
      if(element.id === id) {
        element.style.backgroundColor = "goldenrod";
        element.style.color = "black";
      } else {
        element.style.backgroundColor = "black";
        element.style.color = "white";
      }
    }
  }

  return (
    <div className="mb-4 skillSect">
      <div className="skillHeadBtns">
        <div onClick={() => handleClick(frontend, "frontend")} id="frontend">FrontEnd</div>
        <div onClick={() => handleClick(backend, "backend")} id="backend">BackEnd</div>
        <div onClick={() => handleClick(programmingL, "programmingL")} id="programmingL">Languages</div>
        <div onClick={() => handleClick(databases, "database")} id="database">Database</div>
      </div>

      <div className="skillItems row">
        {
          currSkill.map((item, index) => (
            <div key={item.name} className="col-4 col-sm-2 col-lg-1">
              <img src={item.image} alt={item.name} />
              <p>{item.name}</p>
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default SkillSection;
