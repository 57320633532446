import React from 'react'

const MenuBar = (props) => {
  return (
    <div className='menuBar py-3'>
      <img src={props.image} onClick={props.menuClick} alt="Menu" style={{filter: "invert(72%) sepia(93%) saturate(603%) hue-rotate(359deg) brightness(102%) contrast(108%)"}} />
    </div>
  )
}

export default MenuBar
