import React, { useEffect, useState } from "react";

// assets
import textoedit from "../assets/Screenshot (2).png";
import instagramdownloader from "../assets/Screenshot (26).png";
import coin from "../assets/Screenshot (27).png";
import hellochat from "../assets/Screenshot (6).png";
import spotifyclone from "../assets/Screenshot (7).png";
import snakeGame from "../assets/Screenshot (8).png";
import todoapp from "../assets/Screenshot (36).png";
import realspotifyclone from "../assets/Screenshot (61).png";
import blogapp from "../assets/Screenshot (69).png";
import mysterymsg from "../assets/mysterymsg.png";
import sayhi from "../assets/sayhi.png";

const Projects = () => {
  const [mainProjects, setMainProjects] = useState([]);
  const [otherProjects, setOtherProjects] = useState([]);

  const mProjects = [
    {
      name: "SayHi",
      img: sayhi,
      siteURL: "https://sayyhii.netlify.app/",
      githubURL: "https://github.com/iamprincerajv/sayhi-public",
    },
    {
      name: "MysteryMsg",
      img: mysterymsg,
      siteURL: "https://mysterymsg.vercel.app/",
      githubURL: "https://github.com/iamprincerajv/mysterymessage",
    },
    {
      name: "Blog App",
      img: blogapp,
      siteURL: "https://firstblogapp.vercel.app/",
      githubURL: "https://github.com/iamprincerajv/firstblogapp",
    },
  ];

  const oProjects = [
    {
      name: "Spotify Clone (UI)",
      img: realspotifyclone,
      siteURL: "https://realspotifyclone.freewebhostmost.com/",
      githubURL: "https://github.com/iamprincerajv/realspotifyclone",
    },
    {
      name: "HelloChat (Polling)",
      img: hellochat,
      siteURL: "https://hellochat.epizy.com/",
      githubURL: "https://github.com/iamprincerajv/textchat",
    },
    {
      name: "Todo App",
      img: todoapp,
      siteURL: "https://actodo.vercel.app/",
      githubURL: "https://github.com/iamprincerajv/todolist",
    },
    {
      name: "Snake Game (Best with Keyboard)",
      img: snakeGame,
      siteURL: "https://iamprincerajv.github.io/snakegame",
      githubURL: "https://github.com/iamprincerajv/snakegame",
    },
    {
      name: "SpotifyC",
      img: spotifyclone,
      siteURL: "https://iamprincerajv.github.io/spotifyclone/",
      githubURL: "https://github.com/iamprincerajv/spotifyclone",
    },
    {
      name: "Instagram Downloader (Design)",
      img: instagramdownloader,
      siteURL: "https://iamprincerajv.github.io/instagramdownloader/",
      githubURL: "https://github.com/iamprincerajv/instagramdownloader",
    },
    {
      name: "Multi-function Website",
      img: coin,
      siteURL: "https://iamprincerajv.github.io/flipcoin/",
      githubURL: "https://github.com/iamprincerajv/flipcoin",
    },
    {
      name: "Text Edit",
      img: textoedit,
      siteURL: "https://iamprincerajv.github.io/textodit/",
      githubURL: "https://github.com/iamprincerajv/textoditreactdev",
    },
  ];

  useEffect(() => {
    setMainProjects(mProjects);

    setOtherProjects(oProjects);

    // eslint-disable-next-line
  }, []);

  return (
    <div className="projects">
      <div className="mainProject mt-4 mt-sm-5 mb-3 row d-flex justify-content-evenly">
        {mainProjects.map((project, index) => (
          <div
            key={project.githubURL}
            className="mainProjectItem proj1 col-11 col-md-3 mx-3 mb-3"
          >
            <div>
              <img className="image" src={project.img} alt="" />
            </div>
            <div className="mt-2">
              <p className="text-start ms-1">{project.name}</p>
              <div className="d-flex justify-content-evenly">
                <a
                  className="visitSiteA"
                  target="_blank"
                  rel="noreferrer"
                  href={project.siteURL}
                >
                  Visit Site
                </a>
                <a
                  className="githubA"
                  target="_blank"
                  rel="noreferrer"
                  href={project.githubURL}
                >
                  <i
                    className="fa-brands fa-github fa-2x mx-2"
                    style={{ cursor: "pointer" }}
                  ></i>
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>

      <p>More Projects</p>
      <div className="proj mt-4 mb-5 myCardCon snapCard cardScrollBar">
        {otherProjects.map((project, index) => (
          <div key={project.githubURL} className="proj1 mb-sm-4 mb-4 mx-lg-1">
            <div>
              <img className="image" src={project.img} alt="" />
            </div>
            <div className="mt-2">
              <p className="text-start ms-1">{project.name} </p>
              <div className="d-flex justify-content-evenly">
                <a
                  className="visitSiteA"
                  target="_blank"
                  rel="noreferrer"
                  href={project.siteURL}
                >
                  Visit Site
                </a>
                <a
                  className="githubA"
                  target="_blank"
                  rel="noreferrer"
                  href={project.githubURL}
                >
                  <i
                    className="fa-brands fa-github fa-2x mx-2"
                    style={{ cursor: "pointer" }}
                  ></i>
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Projects;
