import React from 'react'

const MenuItems = (props) => {
    return (
        <div className={props.menuClass.join(" ")}>
            <center>
                <button className='mt-4' onClick={props.skillClick}>Skills</button>
                <button onClick={props.servClick}>Services</button>
                <button onClick={props.projectClick}>Projects</button>
                <button onClick={props.chat}>Get In Touch</button>
            </center>
        </div>
    )
}

export default MenuItems
