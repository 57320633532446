import React from 'react'

const Footer = () => {
    return (
        <footer>
            <div className="text-center">
                <a target="_blank" rel="noreferrer" style={{ color: "black" }} href="mailto:workwithprince2022@gmail.com?subject= Hi Prince">
                    <i className="fa-solid fa-envelope fa-2x mx-2" style={{ cursor: 'pointer' }}></i>
                </a>
                <a target="_blank" rel="noreferrer" style={{ color: "black" }} href="https://www.linkedin.com/in/iamprincerajv/">
                    <i className="fa-brands fa-linkedin fa-2x mx-2" style={{ cursor: 'pointer' }}></i>
                </a>
                <a target="_blank" rel="noreferrer" style={{ color: "black" }} href="https://twitter.com/iamprincerajv/">
                    <i className="fa-brands fa-square-x-twitter fa-2x mx-2" style={{ cursor: 'pointer' }}></i>
                </a>
                <a target="_blank" rel="noreferrer" style={{ color: "black" }} href="https://instagram.com/iamprincerajv/">
                    <i className="fa-brands fa-instagram fa-2x mx-2" style={{ cursor: 'pointer' }}></i>
                </a>
                <a target="_blank" rel="noreferrer" style={{ color: "black" }} href="https://github.com/iamprincerajv/">
                    <i className="fa-brands fa-github fa-2x mx-2" style={{ cursor: 'pointer' }}></i>
                </a>
            </div>
        </footer>
    )
}

export default Footer
