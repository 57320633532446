import React from 'react'
import close from '../assets/cancel.svg';

const Services = (props) => {
  return (
    <div className={props.servClass.join(" ")}>
      <img src={close} onClick={props.servClose} alt="close" />
      <div className='d-flex justify-content-center'>
        <h4 className='p-1 m-3 mt-4 text-center'>Services</h4>
      </div>
      <p>Web Development (Frontend & Backend)</p>
    </div>
  )
}

export default Services
